<template>
	<div>
		<v-btn
			color="blue"
			class="white--text ma-2"
			@click="openModal"
		>   
			Edytuj
		</v-btn>

		<v-dialog v-model="modal" max-width="500">  
			<v-card class="pa-4">
				<v-card-text>
                    <v-file-input
                        v-on:change="onAddNewFile"
                        counter
                        id="file"
                        label="Zmień zdjęcie profilowe (format 1:1)"
                        placeholder="Zaznacz swoje pliki"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                    ></v-file-input>
					<v-text-field
						v-model="name"
						label="Imię i nazwisko"
						clearable
						:error-messages="nameErrors"
						@input="$v.name.$touch()"
						@blur="$v.name.$touch()"
					></v-text-field>
					<v-text-field
						v-model="email"
						label="Email"
						clearable
						:error-messages="emailErrors"
						@input="$v.email.$touch()"
						@blur="$v.email.$touch()"
					></v-text-field>
					<v-text-field
						v-model="phone"
						label="Numer telefonu"
						clearable
						:error-messages="phoneErrors"
						@input="$v.phone.$touch()"
						@blur="$v.phone.$touch()"
					></v-text-field>
					<v-combobox
						v-model="langsToEdit"
						:items="langsArray"
						label="Języki"
						multiple
						chips
					></v-combobox>
                    <v-combobox
						v-model="industriesToEdit"
						:items="industriesArray"
						label="Branże"
						multiple
						chips
					></v-combobox>
                    <v-text-field
						v-model="account"
						label="Numer rachunku bankowego"
						clearable
					></v-text-field>
                    <v-alert
                        color="error"
                        icon="$error"
                        v-if="error"
                    >{{ error }}</v-alert>
				</v-card-text>
				<v-card-actions class="ml-4">
					<v-btn
						color="primary"
						@click="update"
						:disabled="$v.$invalid"
						:loading="isLoaded"
					>
						Edytuj 
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
import { validationMixin } from 'vuelidate'
const { required, email, decimal} = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';

const token = localStorage.getItem('user');

export default {
	name: 'EditUser',
    mixins: [validationMixin, errorMsg],
	data(){
        return{
			type: null,
            error: '',
			isLoaded: false,
            name: null,
            email:  null,
            nip: null,
            phone: null,
            account: null,
			langsToEdit: null,
            modal: false,
            id: null,
            files: null,
			langsArray: ["PL", "EN", "DE", "FR", "IT", "CS", "DA,", "ES", "PT", "RU", "SK", "SV", "UK", "HU", "ZH", "HR", "NL", "NB"],
            industriesToEdit: null,
            industriesArray: [
                "Budownictwo",
                "Dietetyka",
                "Edukacja",
                "Erotyka",
                "Farmacja",
                "Finanse/rachunkowość",
                "Fotografia",
                "Fotowoltaika",
                "Fryzjerstwo",
                "Giełda",
                "HR",
                "IT",
                "Inwestycje",
                "Kosmetyka",
                "Kryptowaluty",
                "Lifestyle",
                "Marketing",
                "Meble",
                "Medycyna",
                "Moda",
                "Motoryzacja",
                "Nieruchomości",
                "Prawo",
                "Przemysł",
                "Psychologia",
                "Rolnictwo",
                "Sport",
                "Transport",
                "Turystyka",
                "Wystrój wnętrz",
            ],
        }
	},
	computed: {
        getUser(){
            return this.$store.getters.getUser;
        },
        getID(){
            return this.$store.getters.getUserID
        }
    },
	validations: {
        name: {
            required,
        },
        email: {
            required,
            email
        },
        phone: {
            decimal
        },
    },
	methods: {
        onAddNewFile(){
            const filesToUpload = document.querySelector("#file").files;
            this.files = filesToUpload;
        },
		update(){

            if(this.account == null){
                this.error = 'Pole "Numer rachunku bankowego" jest wymagane.'
                return;
            }
            console.log(this.industriesToEdit);
			this.isLoaded = true;

            let formData = new FormData();

            if(this.files){
                formData.append('file', this.files[0]);
            }

            formData.append('id', this.getID);
            formData.append('accountNumber', this.account);
            formData.append('password', this.getUser.password);
            formData.append('role', this.getUser.role.toLowerCase());
            formData.append('type', this.type);
            formData.append('email', this.email);
            formData.append('payment', this.getUser.payment);
            formData.append('phone', this.phone);
            formData.append('langs', JSON.stringify(this.langsToEdit)); // Jeśli są to tablice, konwertuj na JSON
            formData.append('industries', JSON.stringify(this.industriesToEdit)); // Jak wyżej
            // formData.append('pesel', this.nip);
            formData.append('name', this.name);

            this.$axios.put(`${this.url}api/users/avatar`, formData, { 
                headers: { 
                    'x-access-token': token,
                    'Content-Type': 'multipart/form-data'
                } 
            })
            .then(()=> {
                location.reload(); 
                this.isLoaded = false;
                this.modal = false;
            })
        },
		openModal(){
			this.modal = !this.modal;
			this.type = this.getUser.type
            this.name = this.getUser.name
            this.email = this.getUser.email
            this.nip = this.getUser.pesel
            this.phone = this.getUser.phone
            this.account = this.getUser.accountNumber
            this.langsToEdit = this.getUser.langs
            this.industriesToEdit = this.getUser.industries
            console.log(this.account)
        },
	}
}
</script>