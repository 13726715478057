<template>
<v-container class="d-flex justify-start flex-column align-start ml-2" >

	<EditUserApprentice v-if="getUser.role == 'praktykant'"/>
    <EditUser v-if="getUser.role !== 'praktykant'"/>
    <v-list max-width="700px" shaped class="text-left ml-2">
            <img class="profile__avatar" :src="getUser.avatar ? getUser.avatar : require('../../assets/avt.jpg')" alt="">
            <v-card class="my-4">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-1">{{getUser.name}}</v-list-item-title>
                        <v-list-item-subtitle>Imię i nazwisko</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            <v-card class="my-4">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-1">{{getUser.email}}</v-list-item-title>
                        <v-list-item-subtitle>Email</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            <v-card class="my-4">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-1">{{getUser.phone}}</v-list-item-title>
                        <v-list-item-subtitle>Numer telefonu</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            <v-card class="my-4">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-layout>
                            <v-list-item-title class="headline mb-1" v-for="(lang,i) in getUser.langs" :key="i">{{lang}}</v-list-item-title>
                        </v-layout>
                        <v-list-item-subtitle>Języki</v-list-item-subtitle>
                    </v-list-item-content>
    
                </v-list-item>
            </v-card>

            <v-card class="my-4">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-layout>
                            <v-list-item-title class="headline mb-1" v-for="(industry,i) in getUser.industries" :key="i">{{industry}}</v-list-item-title>
                        </v-layout>
                        <v-list-item-subtitle>Branże</v-list-item-subtitle>
                    </v-list-item-content>
    
                </v-list-item>
            </v-card>
    </v-list>
</v-container>
</template>

<script>
import EditUserApprentice from '@/components/molecules/EditUserApprentice';
import EditUser from '@/components/molecules/EditUser';

export default {
	name: 'MyAccount',
    data(){
        return{
            avatar: '../assets/avt.jpg'
        }
    },
	components: {
		EditUser,
		EditUserApprentice,
	},
    computed: {
        getUser(){
            return this.$store.getters.getUser;
        },
        getID(){
            return this.$store.getters.getUserID
        }
    },

}
</script>

<style lang="scss">
.profile{
    &__avatar{
        width: 80px;
        height: 80px;
        object-fit: cover;
        border: 1px solid #e5e5e5;
        border-radius: 50px;
    }
}
</style>